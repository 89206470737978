<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Determine the atomic symbol, atomic number, and mass number for the isotopes that match each
        of the descriptions below:
      </p>

      <p class="mb-1">
        a) the <b>chalcogen</b> with a <b>mass number</b> of
        <stemble-latex :content="massNumberA" />
      </p>

      <v-simple-table class="mb-8" style="width: 500px">
        <thead>
          <td class="pl-4">Symbol</td>
          <td class="pl-4">Atomic Number</td>
          <td class="pl-4">Mass Number</td>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-symbol-input v-model="inputs.elementA" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.atomicNumberA" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.massNumberA" :disabled="!allowEditing" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-1">
        b) the longest-lived isotope of a radioactive <b>halogen</b> with an atomic number less than
        100
      </p>

      <v-simple-table class="mb-8" style="width: 500px">
        <thead>
          <td class="pl-4">Symbol</td>
          <td class="pl-4">Atomic Number</td>
          <td class="pl-4">Mass Number</td>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-symbol-input v-model="inputs.elementB" :disabled="!allowEditing" />
              <!--            At, 85, 210-->
            </td>
            <td>
              <calculation-input v-model="inputs.atomicNumberB" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.massNumberB" :disabled="!allowEditing" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-1">
        c) the <b>noble gas</b> with
        <stemble-latex :content="electronsC" />
        electrons and
        <stemble-latex :content="neutronsC" />
        neutrons.
      </p>

      <v-simple-table class="mb-8" style="width: 500px">
        <thead>
          <td class="pl-4">Symbol</td>
          <td class="pl-4">Atomic Number</td>
          <td class="pl-4">Mass Number</td>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-symbol-input v-model="inputs.elementC" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.atomicNumberC" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.massNumberC" :disabled="!allowEditing" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-1">
        d) the <b>alkali metal</b> with
        <stemble-latex :content="neutronsD" />
        neutrons
      </p>

      <v-simple-table class="mb-8" style="width: 500px">
        <thead>
          <td class="pl-4">Symbol</td>
          <td class="pl-4">Atomic Number</td>
          <td class="pl-4">Mass Number</td>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-symbol-input v-model="inputs.elementD" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.atomicNumberD" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input v-model="inputs.massNumberD" :disabled="!allowEditing" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question213',
  components: {
    StembleLatex,
    ChemicalSymbolInput,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        elementA: null,
        atomicNumberA: null,
        massNumberA: null,
        elementB: null,
        atomicNumberB: null,
        massNumberB: null,
        elementC: null,
        atomicNumberC: null,
        massNumberC: null,
        elementD: null,
        atomicNumberD: null,
        massNumberD: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    massNumberA() {
      if (this.versionVariableA.value === 1) {
        return '$126$'; // tellurium  52 126
      } else if (this.versionVariableA.value === 2) {
        return '$34$'; // sulfur 16 34
      } else if (this.versionVariableA.value === 3) {
        return '$78$'; // selenium 34 78
      } else if (this.versionVariableA.value === 4) {
        return '$210$'; // polonium 84 210
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    neutronsC() {
      if (this.versionVariableC.value === 1) {
        return '$12$'; // neon, 10, 22
      } else if (this.versionVariableC.value === 2) {
        return '$18$'; // argon, 18, 36
      } else if (this.versionVariableC.value === 3) {
        return '$47$'; // krypton, 36, 83
      } else if (this.versionVariableC.value === 4) {
        return '$77$'; // xenon, 54, 131
      } else {
        return '';
      }
    },
    electronsC() {
      if (this.versionVariableC.value === 1) {
        return '$10$'; // neon
      } else if (this.versionVariableC.value === 2) {
        return '$18$'; // argon
      } else if (this.versionVariableC.value === 3) {
        return '$36$'; // krypton
      } else if (this.versionVariableC.value === 4) {
        return '$54$'; // xenon
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    neutronsD() {
      if (this.versionVariableD.value === 1) {
        return '$12$'; // sodium, 11, 23
      } else if (this.versionVariableD.value === 2) {
        return '$22$'; // potassium, 19, 41
      } else if (this.versionVariableD.value === 3) {
        return '$50$'; // rubidium 37, 87
      } else if (this.versionVariableD.value === 4) {
        return '$78$'; // caesium 55, 133
      } else {
        return '';
      }
    },
  },
};
</script>
